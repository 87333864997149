import React from "react";
import { Link, StaticQuery, graphql } from "gatsby";
import Section from "./Section";

const ArticleFeedFactory = ({ pageId, data }) => {
  //   Remove current page from list, shuffle array and reduce to array to 4 posts
  const posts = data?.allMarkdownRemark.edges
    .filter(({ node }) => node.id !== pageId)
    .sort()
    .splice(0, 4);

  return (
    <Section backgroundColor="bg-gray-50">
      <div className="relative divide-y-2 divide-gray-200">
        <div>
          <h2 className="text-3xl tracking-tight font-extrabold text-gray-700 sm:text-4xl">
            This could also be interesting for you
          </h2>
        </div>
        <div className="mt-6 pt-10 grid lg:grid-cols-2 gap-8 lg:gap-x-5 lg:gap-y-12">
          {posts.map(({ node }) => {
            const { category, title, seo, slug } = node.frontmatter;
            return (
              <div key={node.id}>
                <p className="text-sm font-bold text-orange-brand">
                  {category.text}
                </p>
                <Link to={slug} className="mt-2 block">
                  <p className="text-xl font-semibold text-gray-700">{title}</p>
                  <p className="mt-3 text-base text-gray-500">
                    {seo.description}
                  </p>
                </Link>
                <div className="mt-3">
                  <Link
                    to={slug}
                    className="text-base font-semibold text-orange-brand hover:text-gray-500"
                  >
                    Read more
                  </Link>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </Section>
  );
};

//Static query get all the posts from topics and pass as props to factory above
export default function ArticleFeed({ pageId }) {
  return (
    <StaticQuery
      query={graphql`
        query staticUsersvitorcorreabinaryforgebinaryForgeWebsiteGatsbysrccomponentsArticleFeedJs1144822621 {
          allMarkdownRemark(
            filter: {
              frontmatter: { topic: { eq: true }, draft: { eq: false } }
            }
            sort: { frontmatter: { date: DESC } }
            limit: 1000
          ) {
            edges {
              node {
                id
                frontmatter {
                  slug
                  title
                  category {
                    text
                    link
                  }
                  seo {
                    description
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => <ArticleFeedFactory data={data} pageId={pageId} />}
    />
  );
}
