import React from "react";
import Section from "./Section";

const TextImageSection = ({ data, image, topics, backgroundColor }) => {
  return (
    <Section backgroundColor={backgroundColor}>
      <div className="grid grid-cols-1 items-center gap-y-10 gap-x-16 lg:grid-cols-2">
        <div>
          <h3 className="mt-4 lg:mt-0 text-3xl font-extrabold text-gray-700 tracking-tight sm:text-4xl text-left uppercase">
            {data.title}
          </h3>
          <p className="mt-5 text-base sm:text-xl prose prose-indigo text-gray-500 mx-auto lg:max-w-none lg:row-start-1 lg:col-start-1 text-justify">
            {data.subtitle}
          </p>
          {topics &&
            topics.map(({ content, id }) => (
              <p className="my-4" key={id}>
                {content}
              </p>
            ))}
        </div>
        <div className="bg-gray-100 rounded-lg overflow-hidden">{image}</div>
      </div>
    </Section>
  );
};

export default TextImageSection;
