import React from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import {
  mapZoom,
  mapConfig,
  mapCenter,
  mapStyles,
  settings,
} from "../assets/js/gmap";
import MapIcon from "../assets/images/misc/gMapIconRound.svg";

const containerStyle = {
  width: "100%",
  height: "400px", // Adjust the height as needed
};

const MapContainer = () => {
  return (
    <LoadScript googleMapsApiKey="AIzaSyDUGwSMjG7Dx1qZ1MgAmgwAvKHJEUiKvZw">
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={mapCenter}
        zoom={mapZoom}
        options={{
          styles: mapStyles,
          disableDefaultUI: settings,
          ...mapConfig,
        }}
      >
        <Marker
          position={mapCenter}
          icon={{
            url: MapIcon,
            scaledSize: { width: 48, height: 60 },
            anchor: { x: 24, y: 60 },
          }}
        />
      </GoogleMap>
    </LoadScript>
  );
};

export default MapContainer;
