import { useState, useEffect } from "react";

function useOnScreen(ref) {
  const [isIntersecting, setIntersecting] = useState(true);

  // const observer = new IntersectionObserver(([entry]) =>
  //   setIntersecting(entry.isIntersecting)
  // )

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) =>
      setIntersecting(entry.isIntersecting)
    );
    if (observer) {
      observer.observe(ref.current);
    }
    // Remove the observer as soon as the component is unmounted
    return () => {
      // observer.disconnect()
      if (observer) {
        observer.disconnect();
      }
    };
  }, [ref]);

  return isIntersecting;
}

const isWindowAvailable = typeof window !== "undefined";
const getPosition = () => (isWindowAvailable ? window.pageYOffset : undefined);

function useScroll() {
  const [scrollPosition, setScrollPosition] = useState(getPosition());

  useEffect(() => {
    if (!isWindowAvailable) {
      return false;
    }

    const handleScroll = () => {
      setScrollPosition(getPosition());
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return scrollPosition;
}

export { useOnScreen, useScroll };
