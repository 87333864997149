import React, { Fragment } from "react";
import { Link } from "gatsby";
import { Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { classNames } from "../utils/utilityFunctions";

export default function MenuNav({ navItemName, data, backgroundIcon }) {
  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button
            className={classNames(
              open ? "text-orange-brand" : "text-white",
              "group inline-flex items-center text-base font-sans font-normal hover:text-orange-brand outline-none"
            )}
          >
            <span>{navItemName}</span>
            <ChevronDownIcon
              className={classNames(
                open ? "text-orange-brand" : "text-white",
                "ml-2 h-5 w-5 group-hover:text-orange-brand transition ease-in-out duration-150"
              )}
              aria-hidden="true"
            />
          </Popover.Button>

          <Transition
            show={open}
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel
              static
              className={`absolute z-10 left-1/2 transform -translate-x-1/2 mt-3 px-2 w-screen max-w-md sm:px-0 ${
                data.length > 3 && "2xl:max-w-3xl"
              }`}
            >
              <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                <div
                  className={`relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8 ${
                    data.length > 3 && "2xl:grid-cols-2"
                  }`}
                >
                  {data.map((item) =>
                    item.externalHref ? (
                      <a
                        key={item.name}
                        href={item.href}
                        className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50 transition ease-in-out duration-150"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <div
                          className={`flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md ${
                            backgroundIcon
                              ? "bg-orange-brand"
                              : "bg-transparent"
                          } text-red-800 sm:h-12 sm:w-12`}
                        >
                          {item.icon}
                        </div>
                        <div className="ml-4">
                          <p className="text-base font-medium text-gray-700">
                            {item.name}
                          </p>
                          <p className="mt-1 text-sm text-gray-500 text-justify">
                            {item.description}
                          </p>
                        </div>
                      </a>
                    ) : (
                      <Link
                        key={item.name}
                        to={item.href}
                        className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50 transition ease-in-out duration-150"
                      >
                        <div
                          className={`flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md ${
                            backgroundIcon
                              ? "bg-orange-brand"
                              : "bg-transparent"
                          } text-red-800 sm:h-12 sm:w-12`}
                        >
                          {item.icon}
                        </div>
                        <div className="ml-4">
                          <p className="text-base font-medium text-gray-700">
                            {item.name}
                          </p>
                          <p className="mt-1 text-sm text-gray-500 text-justify">
                            {item.description}
                          </p>
                        </div>
                      </Link>
                    )
                  )}
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
}
