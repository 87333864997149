import React from "react";
import { InlineWidget } from "react-calendly";
import { XIcon } from "@heroicons/react/outline";
import { useCalendar } from "../context/calendar-context";

export default function Calendar() {
  const { state, dispatch } = useCalendar();

  return (
    <div>
      {state.toogle && (
        <div className="h-screen w-full bg-gray-900 bg-opacity-60 block fixed inset-0 z-40">
          <div
            className={`block fixed max-w-6xl mx-auto inset-0 top-24 h-4/5 p-8 bg-gray-900 w-full z-50`}
          >
            <div className="text-right pt-10 mb-4 lg:mb-0">
              <button
                onClick={() => dispatch({ type: "toogleCalendar" })}
                className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-500 hover:text-gray-700 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-orange-brand"
              >
                <XIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <InlineWidget
              url="https://calendly.com/binary-forge/free-consultation"
              styles={{ height: "800px" }}
            />
          </div>
        </div>
      )}
    </div>
  );
}
