import React from "react";
import Section from "./Section";

export default function SimpleHeader({ title, description, image }) {
  return (
    <Section backgroundColor="bg-gray-800">
      <div className="relative">
        <div className="lg:grid lg:grid-cols-2 lg:gap-10">
          <div className="sm:text-center lg:text-left lg:flex lg:items-center">
            <div className="lg:py-24">
              <h1 className="mt-4 text-4xl tracking-wide font-extrabold text-orange-brand sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl uppercase">
                {title}
              </h1>
              {description && (
                <p className="mt-3 text-base text-gray-300 sm:mt-5 sm:text-xl lg:text-2xl">
                  {description}
                </p>
              )}
            </div>
          </div>
          {image && (
            <div className="pb-16 sm:pb-24 lg:m-0 lg:relative">
              <div className="hidden lg:block lg:max-w-none lg:px-0">
                {image}
              </div>
            </div>
          )}
        </div>
      </div>
    </Section>
  );
}
