import React from "react";
import { useCalendar } from "../context/calendar-context";
import Section from "./Section";
export default function Header({
  titleWhite,
  titleOrange,
  description,
  buttonText,
  image,
}) {
  const { dispatch } = useCalendar();
  return (
    <Section backgroundColor="bg-gray-800">
      <div className="relative">
        <div className="lg:grid lg:grid-cols-2 lg:gap-10">
          <div className="sm:text-center lg:text-left lg:flex lg:items-center">
            <div className="lg:py-24">
              <h1 className="mt-4 text-4xl tracking-tight font-extrabold text-white sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl uppercase">
                {titleWhite && <span className="block">{titleWhite}</span>}
                {titleOrange && (
                  <span className="block text-orange-brand">{titleOrange}</span>
                )}
              </h1>
              <p className="mt-3 text-base text-gray-300 sm:mt-5 sm:text-xl lg:text-2xl">
                {description}
              </p>
              <div className="my-6 sm:mt-10">
                <button
                  className="button"
                  onClick={() => dispatch({ type: "toogleCalendar" })}
                >
                  {buttonText}
                </button>
              </div>
            </div>
          </div>
          <div className="pb-16 sm:pb-24 lg:m-0 lg:relative">
            <div className="hidden lg:block lg:max-w-none lg:px-0">{image}</div>
          </div>
        </div>
      </div>
    </Section>
  );
}
