import React from 'react'

const CalendarContext = React.createContext()

function toogleReducer(state, action) {
    switch (action.type) {
        case 'toogleCalendar': {
            return { toogle: !state.toogle }
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`)
        }

    }
}

function CalendarProvider({ children }) {
    const [state, dispatch] = React.useReducer(toogleReducer, { toogle: false })
    const value = { state, dispatch }
    return <CalendarContext.Provider value={value}>{children}</CalendarContext.Provider>
}

function useCalendar() {
    const context = React.useContext(CalendarContext)
    if (context === undefined) {
        throw new Error('useCalendar must be used within a CalendarProvider')
    }
    return context
}

export { CalendarProvider, useCalendar }