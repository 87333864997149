import React from "react";
import { Link } from "gatsby";
import { Popover } from "@headlessui/react";
import { MenuIcon } from "@heroicons/react/outline";
import Logo from "../assets/images/svg/BinaryForgelogoFullWhite.svg";
import { MobileNav, WebNav } from ".";
import { useCalendar } from "../context/calendar-context";

const Nav = ({ isHeaderVisible }) => {
  const { dispatch } = useCalendar();

  return (
    <div className="sticky top-0 z-50 bg-gray-800 py-3" data-container="nav">
      <Popover>
        {({ open }) => (
          <>
            <div
              className={`flex justify-between items-center ${
                isHeaderVisible ? "max-w-7xl" : "max-w-none"
              } mx-auto px-4 py-4 sm:px-6 md:justify-start md:space-x-10 lg:px-8`}
            >
              <div className="flex flex-1 justify-start md:ml-0  lg:w-0 ">
                <Link to="/">
                  <span className="sr-only">Go to Binary Forge main page</span>
                  <Logo className="w-60 lg:w-48 xl:w-64" />
                </Link>
              </div>
              <div className="-mr-2 -my-2 md:hidden">
                <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500">
                  <span className="sr-only">Open menu</span>
                  <MenuIcon className="h-6 w-6" aria-hidden="true" />
                </Popover.Button>
              </div>
              <WebNav />
              <div className="hidden lg:flex">
                <button
                  className="button"
                  onClick={() => dispatch({ type: "toogleCalendar" })}
                >
                  Request free consultation
                </button>
              </div>
            </div>
            {/* Mobile Menu */}
            <MobileNav status={open} />
          </>
        )}
      </Popover>
    </div>
  );
};
export default Nav;
