import React from "react";

const BigImageLeftSection = ({ image, data }) => {
  return (
    <div className="relative">
      <div className="lg:absolute lg:inset-0">
        <div className="lg:absolute lg:inset-y-0 lg:left-0 lg:w-1/2">
          {image}
        </div>
      </div>
      <div className="relative max-w-2xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:grid lg:grid-cols-2 lg:gap-20">
        <div className="lg:col-start-2">
          <div className="text-base max-w-prose mx-auto lg:ml-auto lg:mr-0">
            <h3 className="mt-4 lg:mt-0 text-3xl font-extrabold text-gray-700 tracking-tight sm:text-4xl text-left uppercase">
              {data.title}
            </h3>
            <p className="mt-8 text-lg lg:text-xl text-gray-500 text-justify">
              {data.subtitle}
            </p>
            <div className="mt-5 text-base sm:text-lg lg:text-xl text-gray-500 text-justify">
              {data.topics.map((topic) => (
                <p className="my-4" key={topic.substring(1, 8)}>
                  {topic}
                </p>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BigImageLeftSection;
