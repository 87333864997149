import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import { footerData } from "../data/FooterData";

export default function Footer() {
  return (
    <footer
      className="w-full bottom-0 bg-white"
      aria-labelledby="footer-heading"
    >
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 overflow-hidden">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="space-y-8 xl:col-span-1">
            <StaticImage
              src="../assets/images/svg/BinaryForgelogoBadgeColour.svg"
              className="w-16 lg:w-20"
              alt="Binary Forge Badge"
              placeholder="tracedSVG"
            />

            <p className="text-gray-500 text-base">
              VAT Number: 315891978
              <span className="block text-gray-500 text-base">
                Company Number: 11730895
              </span>
            </p>
            <div className="flex space-x-6">
              {footerData.social.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  rel="noreferrer noopener"
                  target="_blank"
                  className="text-gray-500 hover:text-orange-brand"
                >
                  <span className="sr-only">{item.name}</span>
                  <item.icon className="h-6 w-6" aria-hidden="true" />
                </a>
              ))}
            </div>
          </div>
          <div className="mt-12 grid grid-cols-2 gap-8 xl:mt-0 xl:col-span-2">
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold text-gray-700 tracking-wider uppercase">
                  Services
                </h3>
                <ul className="mt-4 space-y-4">
                  {footerData.services.map((item) => (
                    <li key={item.name}>
                      <Link to={item.href} className="text-base text-gray-500 ">
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-12 md:mt-0">
                <h3 className="text-sm font-semibold text-gray-700 tracking-wider uppercase">
                  Solutions
                </h3>
                <ul className="mt-4 space-y-4">
                  {footerData.solutions.map((item) => (
                    <li key={item.name}>
                      <a
                        href={item.href}
                        className="text-base text-gray-500"
                        rel="noreferrer noopener"
                        target="_blank"
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold text-gray-700 tracking-wider uppercase">
                  Company
                </h3>
                <ul className="mt-4 space-y-4">
                  {footerData.company.map((item) => (
                    <li key={item.name}>
                      <Link
                        href={item.href}
                        className="text-base text-gray-500 "
                      >
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-12 md:mt-0">
                <h3 className="text-sm font-semibold text-gray-700 tracking-wider uppercase">
                  Legal
                </h3>
                <ul className="mt-4 space-y-4">
                  {footerData.legal.map((item) => (
                    <li key={item.name}>
                      <Link
                        href={item.href}
                        className="text-base text-gray-500 "
                      >
                        {item.name}
                      </Link>
                    </li>
                  ))}
                  {/* <li className="pt-10 sm:pt-16">
                    <StaticImage
                      src="../assets/images/certifications/Cyber-Essentials-Badge-High-Res.png"
                      className="w-16 sm:w-20"
                      quality={100}
                      formats={["auto", "PNG"]}
                      alt="Cyber Essentials"
                      placeholder="tracedSVG"
                    />
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-12 border-t border-gray-900 pt-8">
          <p className="text-base text-gray-500 xl:text-center">
            &copy; {new Date().getFullYear()} Binary Forge Solutions :: All
            Rights Reserved
          </p>
        </div>
      </div>
    </footer>
  );
}
