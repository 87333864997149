import React from "react";
import { useCalendar } from "../context/calendar-context";
import Section from "./Section";

export default function Cta({ data, buttonText }) {
  const { dispatch } = useCalendar();
  return (
    <Section backgroundColor="bg-gray-800">
      <div className="lg:flex lg:items-center lg:justify-between">
        <h2 className="text-3xl font-extrabold tracking-tight text-white sm:text-4xl">
          <span className="block">{data.title}</span>
          <span className="block text-orange-brand">{data.subtitle}</span>
        </h2>
        <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0">
          <div className="inline-flex rounded-md shadow">
            <button
              className="button"
              onClick={() => dispatch({ type: "toogleCalendar" })}
            >
              {buttonText}
            </button>
          </div>
        </div>
      </div>
    </Section>
  );
}
