import React from "react";

import Pf from "../assets/images/svg/pushfusionLogo.svg";
import Lf from "../assets/images/svg/lightforgeLogo.svg";
import Icon from "../components/icon";

export const servicesData = [
  {
    name: "Software Development",
    description:
      "Whether you are looking for a mobile app, a full web enterprise solution or something in between.",
    href: "/services/software-development/",
    icon: <Icon className="h-7 w-7" color="white" icon="web-development" />,
    iconMobile: (
      <Icon className="h-6 w-6" color="#e94a2d" icon="web-development" />
    ),
    externalHref: false,
  },
  // {
  //     name: 'IoT',
  //     description: 'We\'ve build, deployed and managed solutions encompassing over half a million devices rolled out globally.',
  //     href: '/services/internet-of-things/',
  //     icon: (<Icon className="h-7 w-7"  color="white" icon="internet-of-things1" />),
  //     iconMobile: (<Icon className="h-6 w-6" color="#e94a2d" icon="internet-of-things1" />),
  //     externalHref: false,
  // },
  {
    name: "Digital Transformation",
    description:
      "Reducing costs and making things better, can it be done? Yes, it can. Here at Binary Forge we’re exceptionally good at it.",
    href: "/services/digital-transformation/",
    icon: <Icon className="h-7 w-7" color="white" icon="iot-color" />,
    iconMobile: <Icon className="h-6 w-6" color="#e94a2d" icon="iot-color" />,
    externalHref: false,
  },
  // {
  //     name: 'Integration',
  //     description: 'We know how to get systems talking; whether that’s software-to-software or software-to-hardware we understand integration at every level.',
  //     href: '/services/integration/',
  //     icon: (<Icon className="h-7 w-7" color="white"  icon="exchange1" />),
  //     iconMobile: (<Icon className="h-6 w-6" color="#e94a2d" icon="exchange1" />),
  //     externalHref: false,
  // },
  {
    name: "Due Diligence",
    description:
      "Adoption, assimilation and acquisition of technology can be both expensive and fraught with risk. We have the skills to help you to make an informed decision.",
    href: "/services/technical-due-diligence",
    icon: <Icon className="h-7 w-7" color="white" icon="search" />,
    iconMobile: <Icon className="h-6 w-6" color="#e94a2d" icon="search" />,
    externalHref: false,
  },
  {
    name: "Team Augmentation",
    description:
      "Let's increase the size of your current development team(s) or supercharge them with some new capabilities.",
    href: "/services/team-augmentation",
    icon: <Icon className="h-7 w-7" color="white" icon="team" />,
    iconMobile: <Icon className="h-6 w-6" color="#e94a2d" icon="team" />,
    externalHref: false,
  },
];

export const solutionsData = [
  {
    name: "pushfusion",
    description:
      "pushfusion is a secure, cloud-based and vendor-agnostic dashboard that can bring information together from devices across your entire estate and present it to you in one place.",
    href: "https://pushfusion.io?utm_source=referral&utm_medium=bf_website",
    icon: <Pf className="h-15 w-15" alt="pushfusion logo" />,
    externalHref: true,
  },
  // {
  //   name: "LightForge",
  //   description:
  //     "LightForge is a unique web-based, wireless control, monitoring and management system for your lighting estate.",
  //   href: "https://lightforge.io?utm_source=referral&utm_medium=bf_website",
  //   icon: <Lf className="h-15 w-15" alt="Lightforge Logo" />,
  //   externalHref: true,
  // },
];
