import React from "react";

export default function Section({
  children,
  backgroundColor = "bg-white",
  position = "start",
}) {
  const positions = {
    middle: "center",
    end: "right",
    start: "left",
  };
  return (
    <div
      className={`${backgroundColor} text-left sm:text-${positions[position]}`}
    >
      <section className="max-w-2xl mx-auto py-20 px-4 sm:px-6 sm:py-28 lg:max-w-7xl lg:px-8">
        {children}
      </section>
    </div>
  );
}
