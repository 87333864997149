import React, { Fragment } from "react";
import { Link } from "gatsby";
import { Popover, Transition } from "@headlessui/react";
import {
  XIcon,
  LocationMarkerIcon,
  CalendarIcon,
  LightningBoltIcon,
} from "@heroicons/react/outline";
import { servicesData, solutionsData } from "../data/NavData";
import Logo from "../assets/images/svg/BinaryForgelogoFullColour.svg";

const callsToAction = [
  { name: "Find Us", href: "/find", icon: LocationMarkerIcon },
  { name: "Case Studies", href: "/case-studies", icon: LightningBoltIcon },
  {
    name: "Book a demo",
    href: "https://calendly.com/pushfusion/book-a-demo",
    icon: CalendarIcon,
  },
];

const MenuMobile = () => (
  <>
    <Transition
      as={Fragment}
      enter="duration-200 ease-out"
      enterFrom="opacity-0 scale-95"
      enterTo="opacity-100 scale-100"
      leave="duration-100 ease-in"
      leaveFrom="opacity-100 scale-100"
      leaveTo="opacity-0 scale-95"
    >
      <Popover.Panel
        focus
        className="absolute top-0 inset-x-0 z-10 p-2 transition transform origin-top-right md:hidden"
      >
        <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
          <div className="pt-5 pb-6 px-5">
            <div className="flex items-center justify-between">
              <div>
                <Logo className="w-48" />
              </div>
              <div className="-mr-2">
                <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-orange-brand">
                  <span className="sr-only">Close menu</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </Popover.Button>
              </div>
            </div>
            <div className="mt-6">
              <nav className="grid gap-y-8">
                {servicesData.map((item) => (
                  <Link
                    key={item.name}
                    to={item.href}
                    className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50"
                  >
                    <div
                      className={`flex-shrink-0 flex items-center justify-center h-10 w-10`}
                    >
                      {item.iconMobile}
                    </div>
                    <span className="ml-3 text-base font-medium text-gray-700">
                      {item.name}
                    </span>
                  </Link>
                ))}
              </nav>
            </div>
          </div>
          <div className="py-6 px-5 space-y-6">
            <div className="grid grid-cols-2 gap-y-4 gap-x-8">
              {solutionsData.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  className="-m-3 p-3 flex items-start"
                >
                  <div className="flex-shrink-0 flex items-center justify-center h-6 w-6">
                    {item.icon}
                  </div>

                  <div className="ml-4">
                    <p className="text-base font-medium text-gray-700">
                      {item.name}
                    </p>
                  </div>
                </a>
              ))}
            </div>
          </div>
          <div className="bg-gray-50">
            <div className="px-6 py-6 bg-gray-50 space-y-6 sm:flex sm:space-y-0 sm:space-x-10">
              {callsToAction.map((item) => (
                <div key={item.name} className="flow-root">
                  <a
                    href={item.href}
                    className="-m-3 p-3 flex items-center rounded-md text-base font-medium text-gray-700 hover:bg-gray-100 transition ease-in-out duration-150"
                  >
                    <item.icon
                      className="flex-shrink-0 h-6 w-6 text-gray-400"
                      aria-hidden="true"
                    />
                    <span className="ml-3">{item.name}</span>
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Popover.Panel>
    </Transition>
  </>
);

export default MenuMobile;
