import React from "react";
import { Link } from "gatsby";
import { Popover } from "@headlessui/react";
import { MenuNav } from ".";
import { servicesData, solutionsData } from "../data/NavData";

const WebNav = () => {
  return (
    <Popover.Group
      as="nav"
      className="hidden md:flex md:items-center space-x-6 g:space-x-8 xl:space-x-10 mr-10"
    >
      <MenuNav
        navItemName="Services"
        data={servicesData}
        backgroundIcon={true}
      />
      <MenuNav
        navItemName="Solutions"
        data={solutionsData}
        backgroundIcon={false}
      />
      <Link
        to="/case-studies"
        className="text-white hover:text-orange-brand sm:"
      >
        Case Studies
      </Link>
      <Link to="/about" className="text-white hover:text-orange-brand sm:">
        About
      </Link>
      <Link to="/find" className="text-white hover:text-orange-brand">
        Find Us
      </Link>
    </Popover.Group>
  );
};

export default WebNav;
