exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-case-studies-andel-js": () => import("./../../../src/pages/case-studies/andel.js" /* webpackChunkName: "component---src-pages-case-studies-andel-js" */),
  "component---src-pages-case-studies-detectronic-js": () => import("./../../../src/pages/case-studies/detectronic.js" /* webpackChunkName: "component---src-pages-case-studies-detectronic-js" */),
  "component---src-pages-case-studies-js": () => import("./../../../src/pages/case-studies.js" /* webpackChunkName: "component---src-pages-case-studies-js" */),
  "component---src-pages-case-studies-steeper-js": () => import("./../../../src/pages/case-studies/steeper.js" /* webpackChunkName: "component---src-pages-case-studies-steeper-js" */),
  "component---src-pages-find-js": () => import("./../../../src/pages/find.js" /* webpackChunkName: "component---src-pages-find-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-services-digital-transformation-js": () => import("./../../../src/pages/services/digital-transformation.js" /* webpackChunkName: "component---src-pages-services-digital-transformation-js" */),
  "component---src-pages-services-software-development-js": () => import("./../../../src/pages/services/software-development.js" /* webpackChunkName: "component---src-pages-services-software-development-js" */),
  "component---src-pages-services-team-augmentation-js": () => import("./../../../src/pages/services/team-augmentation.js" /* webpackChunkName: "component---src-pages-services-team-augmentation-js" */),
  "component---src-pages-services-technical-due-diligence-js": () => import("./../../../src/pages/services/technical-due-diligence.js" /* webpackChunkName: "component---src-pages-services-technical-due-diligence-js" */),
  "component---src-templates-topic-template-js": () => import("./../../../src/templates/topicTemplate.js" /* webpackChunkName: "component---src-templates-topic-template-js" */)
}

