import React from "react";
import Section from "./Section";

const BigIconsSection = ({ titleData, data }) => {
  return (
    <Section>
      <div className="relative">
        <h2 className="mt-2 text-3xl font-extrabold text-gray-700 tracking-tight sm:text-5xl text-left">
          {titleData.title}
        </h2>
        <p className="mt-5 mx-auto text-xl text-gray-700 text-justify">
          {titleData.subtitle}
        </p>
      </div>
      <div className="mt-12 text-center">
        <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
          {data.map((feature) => (
            <div key={feature.name} className="pt-6">
              <div className="flow-root rounded-lg px-6 pb-8">
                <div className="-mt-6">
                  <div>
                    <span className="inline-flex items-center justify-center p-3 bg-orange-brand rounded-md shadow-lg">
                      <feature.icon
                        className="h-20 w-20 text-white"
                        aria-hidden="true"
                      />
                    </span>
                  </div>
                  <h3 className="mt-8 text-3xl font-medium text-gray-700 tracking-tight">
                    {feature.name}
                  </h3>
                  <p className="mt-5 text-xl text-gray-500">{feature.text}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Section>
  );
};

export default BigIconsSection;
