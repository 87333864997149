import React from "react";
import { Link } from "gatsby";
import Icon from "./icon";
import Logo from "../assets/images/svg/BinaryForgelogoBadgeColour.svg";

export default function CookiesBanner() {
  return (
    <div className="min-h-full pt-10 flex flex-col bg-white">
      <div className="flex-grow flex flex-col max-w-none w-full px-4 sm:px-6 lg:px-8">
        {/* <div className="flex-shrink-0 flex flex-row bg-red-500"> */}
        <div className="flex flex-col sm:flex-row sm:items-center justify-between">
          <Icon
            className="order-2 sm:order-1 mt-4 sm:mt-0 h-12 w-12"
            color="rgb(55 65 81)"
            icon="cookie"
          />
          <Logo className="order-1 sm:order-2 w-12 h-auto" />
        </div>

        <div className="pt-4 pb-8">
          <div>
            <h1 className="mt-2 text-xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">
              This website uses cookies
            </h1>
            <div className="mt-2 text-base text-gray-500 text-justify">
              <p>
                At Binary Forge, we use cookies to give you the best experience
                on our website. For more details see our{" "}
                <Link to="/privacy">privacy policy</Link>.
              </p>
              <p className="mt-2">Are you happy to accept them?</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
