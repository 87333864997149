import React from "react";
import Section from "./Section";

const TopicPostsSection = ({
  titleData,
  postsData,
  backgroundColor,
  title = true,
}) => {
  return (
    <>
      <Section backgroundColor={backgroundColor}>
        <div className="relative">
          {!!title && (
            <h3 className="mt-4 lg:mt-0 text-3xl font-extrabold text-gray-700 tracking-tight sm:text-4xl uppercase">
              {titleData.title}
            </h3>
          )}

          <div className="mt-12 max-w-none mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
            {postsData.map((post) => (
              <div
                key={post.title}
                className="flex flex-col rounded-lg shadow-lg overflow-hidden"
              >
                {post.isLogo ? (
                  <div
                    className="flex items-center justify-center h-48 w-full"
                    style={{ backgroundColor: post.colour }}
                  >
                    {post.image}
                  </div>
                ) : (
                  <div className="flex-shrink-0">{post.image}</div>
                )}

                <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                  <div className="flex-1">
                    <p className="text-sm font-medium text-orange-brand">
                      <a href={post.category.href} className="hover:underline">
                        {post.category.name}
                      </a>
                    </p>
                    <a href={post.href} className="block mt-2">
                      <p className="text-xl font-semibold text-gray-700">
                        {post.title}
                      </p>
                      <p className="mt-3 text-base text-gray-500 text-justify">
                        {post.description}
                      </p>
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Section>
    </>
  );
};

export default TopicPostsSection;
