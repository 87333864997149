export const mapCenter = {
    lat: 53.55528,
    lng: -1.47931,
}
export const mapZoom = 12
export const mapConfig = {
    width: "100%",
    height: "100%",
}
export const mapStyles = [
    {
        featureType: "administrative",
        elementType: "labels.text.fill",
        stylers: [{ color: "#333333" }],
    },
    {
        featureType: "administrative",
        elementType: "labels.text.stroke",
        stylers: [{ visibility: "on" }, { color: "#ffffff" }, { weight: "1" }],
    },
    {
        featureType: "landscape",
        elementType: "all",
        stylers: [{ hue: "#F8AC31" }, { saturation: "-20" }, { lightness: "80" }],
    },
    {
        featureType: "landscape.man_made",
        elementType: "labels",
        stylers: [{ visibility: "off" }],
    },
    {
        featureType: "landscape.man_made",
        elementType: "geometry",
        stylers: [{ visibility: "on" }, { hue: "#F8AC31" }, { saturation: "-30" }, { lightness: "25" }, { gamma: "0.2" }],
    },
    {
        featureType: "landscape.natural",
        elementType: "labels",
        stylers: [{ visibility: "off" }],
    },
    {
        featureType: "poi",
        elementType: "all",
        stylers: [{ visibility: "off" }],
    },
    {
        featureType: "poi.park",
        elementType: "geometry.fill",
        stylers: [{ color: "#edc483" }, { visibility: "on" }],
    },
    {
        featureType: "road",
        elementType: "all",
        stylers: [{ saturation: -100 }, { lightness: "0" }],
    },
    {
        featureType: "road.highway",
        elementType: "all",
        stylers: [{ visibility: "simplified" }],
    },
    {
        featureType: "road.highway",
        elementType: "geometry.fill",
        stylers: [{ color: "#e94a2d" }],
    },
    {
        featureType: "road.highway",
        elementType: "labels.icon",
        stylers: [
            {
                gamma: "3",
            },
        ],
    },
    {
        featureType: "road.arterial",
        elementType: "geometry.fill",
        stylers: [{ color: "#e5e5e5" }],
    },
    {
        featureType: "road.arterial",
        elementType: "geometry.stroke",
        stylers: [{ color: "#e0e0e0" }],
    },
    {
        featureType: "road.arterial",
        elementType: "labels.icon",
        stylers: [{ visibility: "off" }],
    },
    {
        featureType: "transit",
        elementType: "all",
        stylers: [{ visibility: "off" }],
    },
    {
        featureType: "water",
        elementType: "geometry.fill",
        stylers: [{ visibility: "on" }, { color: "#333333" }],
    },
    {
        featureType: "water",
        elementType: "labels.text.fill",
        stylers: [{ visibility: "on" }, { color: "#ffffff" }],
    },
    {
        featureType: "water",
        elementType: "labels.text.stroke",
        stylers: [{ visibility: "off" }],
    },
]

export const settings = true