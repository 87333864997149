import React from "react";
import { Link } from "gatsby";

export default function ArticleFooter() {
  return (
    <div className="pt-20">
      <div className="border-t-2 border-gray-300 py-10" />
      <div>
        <div className="text-xl font-bold tracking-wide text-gray-500 uppercase pb-1 md:space-y-0 text-center">
          <p>Connect with us</p>
        </div>
      </div>

      <div className="p-8 px-40 flex items-center justify-around">
        {/* <a
          href="https://www.linkedin.com/company/forge-binary"
          className="text-white hover:text-orange-brand transform transition hover:-translate-y-0.5 rounded-full bg-orange-brand hover:bg-gray-400"
        >
          <span className="sr-only">LinkedIn</span>
          <svg
            className="h-12 w-12 p-3"
            fill="currentColor"
            aria-hidden="true"
            viewBox="0 0 448 512"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"></path>
          </svg>
        </a> */}
        <a
          href="https://twitter.com/forgebinary"
          className="text-white hover:text-orange-brand transform transition hover:-translate-y-0.5 rounded-full bg-orange-brand hover:bg-gray-400"
        >
          <span className="sr-only">Twitter</span>
          <svg
            className="h-12 w-12 p-3"
            fill="currentColor"
            viewBox="0 0 24 24"
            aria-hidden="true"
          >
            <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
          </svg>
        </a>
        <Link
          to="/find"
          className="text-white hover:text-orange-brand transform transition hover:-translate-y-0.5 rounded-full bg-orange-brand hover:bg-gray-400"
        >
          <span className="sr-only">Contact us</span>
          <svg
            className="h-12 w-12 p-3"
            fill="currentColor"
            viewBox="0 0 24 24"
            aria-hidden="true"
          >
            <path d="M23,20 L23,6 L12,15 L1,6 L1,20 L23,20 Z M12,12 L22,4 L2,4 L12,12 Z" />
          </svg>
        </Link>
      </div>
    </div>
  );
}
