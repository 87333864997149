/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import CookieConsent, { getCookieConsentValue } from "react-cookie-consent";
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies";
import { useLocation } from "@reach/router";
import { Footer, Calendar, CookiesBanner } from ".";
import { CalendarProvider } from "../context/calendar-context";
import { useScroll } from "../utils/hooks";

const Layout = ({ children }) => {
  const location = useLocation();
  const scrollPosition = useScroll();
  //If gatsby-plugin-gdpr-cookies is undefined, hide cookies banner until user scroll page, otherwise use the cookie value.
  const [showCookiesBanner, setShowCookiesBanner] = useState(
    getCookieConsentValue("gatsby-gdpr-google-tagmanager") === undefined
      ? "hidden"
      : "byCookieValue"
  );

  useEffect(() => {
    if (showCookiesBanner === "hidden" && scrollPosition >= 10) {
      setShowCookiesBanner("byCookieValue");
    }
  }, [scrollPosition, showCookiesBanner]);

  return (
    <CalendarProvider>
      <Calendar />
      <div>
        <main className="flex-grown">{children}</main>
      </div>
      <div className="popup">
        <CookieConsent
          // debug={true}
          visible={showCookiesBanner}
          disableStyles
          enableDeclineButton
          location="none"
          buttonText="Yes"
          declineButtonText="No"
          cookieName="gatsby-gdpr-google-tagmanager"
          overlay
          overlayClasses="overlayClass"
          containerClasses="cookieConsentClass"
          buttonWrapperClasses="cookieButtonWrapper"
          buttonClasses="button cookieAcceptButton"
          declineButtonClasses="cookieDeclineButton"
          onAccept={() => {
            initializeAndTrack(location);
          }}
        >
          <CookiesBanner />
        </CookieConsent>
      </div>
      <Footer />
    </CalendarProvider>
  );
};

export default Layout;
