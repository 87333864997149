export function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export function template(content, data) {
  return content.replace(/{(.+)}/g, (match, key) => {
    let value = data[key];

    if (typeof value !== "undefined") {
      if (key === "COMPANY_WEBSITE") {
        return `<a href="https://${data["COMPANY_WEBSITE"]}">${data["COMPANY_NAME"]}</a>`;
      } else {
        return value;
      }
    }
    // guards against some unintentional prefix
    return match;
  });
}
