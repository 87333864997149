import React from "react";
import Section from "./Section";

const FeaturesSection = ({ data, children, backgroundColor }) => (
  <Section backgroundColor={backgroundColor}>
    <div className="relative">
      <h2 className="text-center text-gray-700">{data.title}</h2>
      <p className="subtitle">{data.subTitle}</p>
    </div>
    {children}
  </Section>
);

export default FeaturesSection;
