import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { MailIcon } from "@heroicons/react/outline";
import ReCAPTCHA from "react-google-recaptcha";
import Section from "./Section";
//Components

//Assets

const API_GATEWAY_URL =
  "https://mk1w7p8at0.execute-api.eu-west-2.amazonaws.com/production/";

const ContactForm = ({ styles }) => {
  const [isVerified, setVerifyCallback] = useState(false);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [isError, setIsError] = useState(false);
  const [bttnMsg, setBttnMsg] = useState("Get In Touch");
  const {
    register,
    errors,
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    mode: "onTouched",
    criteriaMode: "all",
  });

  const verifyCallback = (response) => {
    if (response) {
      setVerifyCallback(true);
    }
  };

  const isSubmitDisabled = (submitting) => {
    let disabled = true;
    if (isVerified && !submitting) {
      disabled = false;
    }
    return disabled;
  };

  const onSubmit = async (data) => {
    try {
      if (isVerified) {
        setBttnMsg("Sending");
        await fetch(API_GATEWAY_URL, {
          method: "POST",
          // mode: "cors",
          cache: "no-cache",
          body: JSON.stringify(data),
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        });

        setIsSuccessful(true);
        setBttnMsg("Get In Touch");
        reset();
      }
    } catch (error) {
      setIsError(true);
    }
  };

  return (
    <div className="bg-gray-50 featureSection">
      <Section backgroundColor="bg-gray-50">
        {isSuccessful && (
          <div className="text-3xl font-bold text-gray-700">
            <p className="text-orange-brand">Thanks for getting in touch.</p>
            <p>Someone will get back to you as soon as possible.</p>
          </div>
        )}

        {!isSuccessful && (
          <>
            <p className="text-3xl text-gray-700 tracking-tight">
              Interested In What We Do?
            </p>
            <p className="text-5xl font-bold text-orange-brand mb-16 tracking-tight">
              Send Us a Message...
            </p>

            {isError && (
              <div className="mb-16 text-3xl font-bold">
                <p className="text-gray-700">Oops, something went wrong.</p>
                <p className="text-gray-500">
                  Please try again or email us directly.
                </p>
                <p className="mt-6 flex">
                  <div className="flex-shrink-0">
                    <MailIcon
                      className="h-6 w-6 text-orange-brand"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 text-base text-gray-500">
                    <p>info@binaryforge.io</p>
                  </div>
                </p>
              </div>
            )}
            <div className="py-4 xl:py-6">
              <form
                className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="col-span-2 sm:col-span-1">
                  <label htmlFor="name">Full Name:</label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    className="form"
                    // placeholder="Full Name"
                    disabled={isSubmitting}
                    ref={register({ required: true, maxLength: 50 })}
                  />
                  <ul className="errors">
                    {errors?.name?.types?.required && (
                      <li className="error">Please enter your full name</li>
                    )}
                    {errors?.name?.types?.maxLength && (
                      <li className="error">
                        Full name cannot be more than 50 characters
                      </li>
                    )}
                  </ul>
                </div>
                <div className="col-span-2 sm:col-span-1">
                  <label htmlFor="email">Email:</label>
                  <input
                    type="text"
                    id="email"
                    name="email"
                    // placeholder="email"
                    className="form"
                    disabled={isSubmitting}
                    ref={register({
                      required: true,
                      maxLength: 50,
                      pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    })}
                  />
                  <ul className="errors">
                    {errors?.email?.types?.required && (
                      <li className="error">Please enter your email address</li>
                    )}
                    {errors?.email?.types?.minLength && (
                      <li className="error">
                        Email cannot be more than 50 characters
                      </li>
                    )}
                    {errors?.email?.types?.pattern && (
                      <li className="error">
                        Not a correctly formatted email address
                      </li>
                    )}
                  </ul>
                </div>
                <div className="sm:col-span-2">
                  <label htmlFor="message">Message:</label>
                  <div className="mt-1">
                    <textarea
                      id="message"
                      name="message"
                      // placeholder="Message"
                      className="py-3 px-4 block w-full shadow-sm focus:ring-orange-brand focus:border-orange-brand border border-gray-300 rounded-md"
                      rows={2}
                      disabled={isSubmitting}
                      ref={register({ required: true })}
                    ></textarea>
                    <ul className="errors">
                      {errors?.message?.types?.required && (
                        <li className="error">Please enter a message</li>
                      )}
                    </ul>
                  </div>
                </div>

                <div className="col-span-2 space-y-4 lg:space-y-0 lg:flex lg:items-end lg:justify-between">
                  <div>
                    <ReCAPTCHA
                      sitekey="6Lc0BcoZAAAAAHsIInDbJrXVy5eYFaGGYJKtpXjd"
                      // render="explicit"
                      // onloadCallback={handleCaptchaLoad}
                      onChange={verifyCallback}
                    />
                  </div>
                  <button
                    className={`button ${
                      isSubmitDisabled(isSubmitting) &&
                      "opacity-25 cursor-not-allowed"
                    }`}
                    type="submit"
                    value={bttnMsg}
                  >
                    {bttnMsg}
                  </button>
                </div>
              </form>
            </div>
          </>
        )}
      </Section>
    </div>
  );
};

export default ContactForm;
